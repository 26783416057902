.intro {
  background: linear-gradient(152.62deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 101%), rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(50px);
  padding-top: 3rem;
  height: 100%;
  &__container {
    height: inherit;
    padding: 0.5rem 3rem;
    .intro {
      &__content {
        overflow-y: auto;
        .content {
          &__data {
            .whatAreEtas {
              .ulStyle {
                margin-left: 10px;
                list-style: inside;
              }
            }
            .liquidityAndSecurity {
              .headerLogo {
                text-align: right;
                text-align: -webkit-right;
              }
            }
          }
        }
      }
      &__content::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
    }
    .content {
      &__header {
        padding-bottom: 1.5rem;
        .introducing {
          font-weight: 300;
          font-size: 16px;
        }
      }
    }
  }
  &__header {
    column-gap: 10px;
    font-size: 11px;
    justify-content: space-between;
    .scrollMenu {
      cursor: pointer;
    }
  }
  .horizontalLine {
    padding: 1.5rem 0;
  }
}

/* Scrollbar styles */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px #f4f2f2;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #ddd;
  box-shadow: inset 0 0 6px rgba(126, 124, 124, 0.5);
}

::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background: #f0f0f0;
  box-shadow: inset 0 0 6px rgba(243, 241, 241, 0.5);
}

html {
  scroll-behavior: smooth;
}
