select {
  margin: 2rem;
  width: 20rem;
  border: 1px solid #dcdcdc;
  padding: 10px;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  border-radius: 0.25rem;
}
