.selectStrategy {
  background-color: #f1f1f1;
  .description {
    background-color: white;
    border: 1px solid #ddd;
    height: auto;
  }
  
  .etaType {
    margin-bottom: 2rem;
  }
}