.line {
    stroke-width: 2;
    fill: none;
  }
  
  .axis path {
    stroke: black;
  }

  .y.axis .domain {
    stroke: none;
  }
  
  .text {
    font-size: 12px;
  }
  
  .title-text {
    font-size: 12px;
  }

  .svg-container {
    display: inline-block;
    position: relative;
    width: 100%;
    padding-bottom: 100%; /* aspect ratio */
    vertical-align: top;
    overflow: hidden;
  }
  .svg-content-responsive {
    display: inline-block;
    position: absolute;
    top: 10px;
    left: 0;
  }
  svg .rect {
    fill: gold;
    stroke: steelblue;
    stroke-width: 5px;
  }