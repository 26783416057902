html{
  -ms-overflow-style:none;
  -webkit-text-size-adjust:100%;
  overflow:y;
}
body,html{
  height:100vh;
  margin:0;
  touch-action:pan-x pan-y;
  width:100%
}
body{
  background-color:#000;

}
a,body{
  color:#000
}
a{
  text-decoration:none
}
h1.seo__title,h2.seo__subtitle{
  opacity:0;
  pointer-events:none;
  position:absolute;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  -o-user-select:none;
  user-select:none
}
div.hidden{
  display:none
}
div.before_exit,div.hidden{
  pointer-events:none
}
.reusable-elements{
  display:none
}
input:focus,select:focus,textarea:focus{
  outline:none
}
::-webkit-scrollbar{
  height:8px;
  width:6px
}
::-webkit-scrollbar-track{
  background-color:transparent
}
::-webkit-scrollbar-thumb{
  background-color:#707070
}
::placeholder{
  color:#949494
}
:-ms-input-placeholder{
  color:#949494
}
::-ms-input-placeholder{
  color:#949494
}
.button{
  cursor:pointer;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  -o-user-select:none;
  user-select:none
}
.canvas-container{
  display:flex;
  justify-content:center;
  position:absolute;
  z-index: 1;
  touch-action:none;
  width:auto;
}
.canvas-container,.canvas-container canvas{
  height:100%;
  width:100%;
}
.section{
  display:grid;
  min-height:100%;
  opacity:0;
  position:absolute;
  width:100%;
  z-index:1
}
.section.hidden{
  display:none;
  opacity:0
}
.container-cube{
  min-height:100%;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  -o-user-select:none;
  user-select:none;
  position:static;

  z-index:1;
}
.component{
  display:grid;
  position:absolute;
  z-index:1
}
.loader{
  background-color:#fff;
  place-items:center;
  top:0;
  z-index:999
}
.loader__group{
  align-items:center;
  display:flex;
  flex-direction:column
}
.loader__animation{
  display:flex;
  width:300px
}
.loader__animation * path{
  stroke:#fff
}
.loader__progress-bar{
  background:#909090;
  border-radius:6px;
  max-width:300px;
  overflow:hidden;
  position:relative;
  width:60%;
  will-change:transform
}
.loader__progress-bar-fill{
  background-color:#000;
  border-radius:20px;
  height:8px;
  left:-100%;
  position:relative;
  transform-origin:left;
  width:100%;
  will-change:transform
}
