/* This stylesheet generated by Transfonter (https://transfonter.org) on December 7, 2017 3:38 PM */

@font-face {
    font-family: 'DIN 2014';
    src: url('DIN2014-LightItalic.eot');
    src: local('DIN 2014 Light Italic'), local('DIN2014-LightItalic'),
        url('DIN2014-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('DIN2014-LightItalic.woff') format('woff'),
        url('DIN2014-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'DIN 2014 lgt';
    src: url('DIN2014-Light.eot');
    src: local('DIN 2014 Light'), local('DIN2014-Light'),
        url('DIN2014-Light.eot?#iefix') format('embedded-opentype'),
        url('DIN2014-Light.woff') format('woff'),
        url('DIN2014-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'DIN 2014';
    src: url('DIN2014-ExtraBoldItalic.eot');
    src: local('DIN 2014 ExtraBold Italic'), local('DIN2014-ExtraBoldItalic'),
        url('DIN2014-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('DIN2014-ExtraBoldItalic.woff') format('woff'),
        url('DIN2014-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'DIN 2014 exl';
    src: url('DIN2014-ExtraLight.eot');
    src: local('DIN 2014 ExtraLight'), local('DIN2014-ExtraLight'),
        url('DIN2014-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('DIN2014-ExtraLight.woff') format('woff'),
        url('DIN2014-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'DIN 2014';
    src: url('DIN2014-ExtraBold.eot');
    src: local('DIN 2014 ExtraBold'), local('DIN2014-ExtraBold'),
        url('DIN2014-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('DIN2014-ExtraBold.woff') format('woff'),
        url('DIN2014-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'DIN 2014';
    src: url('DIN2014-ExtraLightItalic.eot');
    src: local('DIN 2014 ExtraLight Italic'), local('DIN2014-ExtraLightItalic'),
        url('DIN2014-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('DIN2014-ExtraLightItalic.woff') format('woff'),
        url('DIN2014-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'DIN 2014 reg';
    src: url('DIN2014-Regular.eot');
    src: local('DIN 2014 Regular'), local('DIN2014-Regular'),
        url('DIN2014-Regular.eot?#iefix') format('embedded-opentype'),
        url('DIN2014-Regular.woff') format('woff'),
        url('DIN2014-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DIN 2014';
    src: url('DIN2014-Italic.eot');
    src: local('DIN 2014 Italic'), local('DIN2014-Italic'),
        url('DIN2014-Italic.eot?#iefix') format('embedded-opentype'),
        url('DIN2014-Italic.woff') format('woff'),
        url('DIN2014-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'DIN 2014 bol';
    src: url('DIN2014-Bold.eot');
    src: local('DIN 2014 Bold'), local('DIN2014-Bold'),
        url('DIN2014-Bold.eot?#iefix') format('embedded-opentype'),
        url('DIN2014-Bold.woff') format('woff'),
        url('DIN2014-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DIN 2014';
    src: url('DIN2014-BoldItalic.eot');
    src: local('DIN 2014 Bold Italic'), local('DIN2014-BoldItalic'),
        url('DIN2014-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('DIN2014-BoldItalic.woff') format('woff'),
        url('DIN2014-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'DIN 2014';
    src: url('DIN2014-DemiBoldItalic.eot');
    src: local('DIN 2014 DemiBold Italic'), local('DIN2014-DemiBoldItalic'),
        url('DIN2014-DemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('DIN2014-DemiBoldItalic.woff') format('woff'),
        url('DIN2014-DemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'DIN 2014';
    src: url('DIN2014-DemiBold.eot');
    src: local('DIN 2014 DemiBold'), local('DIN2014-DemiBold'),
        url('DIN2014-DemiBold.eot?#iefix') format('embedded-opentype'),
        url('DIN2014-DemiBold.woff') format('woff'),
        url('DIN2014-DemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
