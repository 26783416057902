.main {
    min-height: calc(100vh - 100px);
    height: auto;
}
.market-menu.show {
  width: 33.3% !important;
  display: flex !important;
}
.market-menu {
  background: linear-gradient(152.62deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 101%), rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(50px);
}
.eta-index-model {
    position: relative;
    z-index:1;
}
.eta-index-container {
    position: relative;
    z-index:5;
}