select {
  margin: 0rem;
  width: 20rem;
  border: 1px solid #dcdcdc;
  padding: 10px;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  border-radius: 0.25rem;
  color: #5A6267;
  &:disabled {
    cursor: not-allowed;
  }
  option {
    color: #000;
    &.green:hover {
      background-color: #73BD59 !important;
    }
    &.red:hover {
      background-color: #DB3155 !important;
    }
    &.blue:hover {
      background-color: #1C59A8 !important;
    }
  }
}
