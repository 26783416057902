.prism-perspective {
  .eta-showcase-content{
    gap: 150px;
  }
}

.eta-index-model {
    position: relative;
    z-index:1;
}
.eta-index-container {
    position: relative;
    z-index:5;
}