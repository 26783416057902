.alternativePosition {
    position: relative;
    .equal {
        position: absolute;
        bottom: 48%;
        left: 59%;
    }
    .plus {
        position: absolute;
        bottom: 46%;
        left: 28%;
    }
}