@import "../../fonts/stylesheet.css";

.eta-showcase {
    font-family: "DIN 2014 reg";
  &.container {
    max-width: 100%;
  }
  .eta-header {
    &__security-logo img{
      height: 35px;
    }
  }
  .security-info {
    color: #FFF;
    font-weight: bold;
    font-size: 15.5px;
    width: 40%;
    margin: auto;
  }
  .security-autocomplete {
    &__label {
      color: #FFF;
      font-size: 20px;
    }
    width: 40%;
    margin: auto;
    .ant-select-single.ant-select-open .ant-select-selection-item {
      color:#dfdad5;
    }
    .ant-select-show-search {
      width: 100%;
    }
    .ant-select-selector {
      background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
      backdrop-filter: blur(70px);
      .ant-select-selection-placeholder {
        color:#dfdad5;
        transition: all 0.1s;
        text-align: center;
      }
      .ant-select-selection-search {
        color: #FFF;
      }
      .ant-select-selection-item {
        color: #fff;
        font-weight: bold;
        transition: all 0.1s;
        text-align: center;
      }
      .ant-select-selection-search-input {
        text-align: center;
      }
    }
  }
  .eta-content {
    color: #fff;
    .section-image {
      margin-top: 455px;
    }
    .section-logo {
      height: 175px;
      width: 200px;
    }
    .income-profile-container {
      max-width: 350px;
      min-width: 350px;
    }
    .growth-profile-container {
      max-width: 350px;
      min-width: 350px;
    }
    .eta-transition-block.transition.height.show {
      max-height: 500px !important;
    }
    .eta-header {
      line-height: 1.2;
      .header-label {
        font-size: 36px;
      }
      .header-info {
        font-size: 20px;
      }
    }
    .eta-profile-info {
      &__title {
        font-family: "DIN 2014 exl";
        font-size: 26px;
      }
      &__sub-title {
        font-size: 10px;
      }
      &__bar {
        border: 3px solid;
      }
      .eta-profile-content {
        &.growth-profile {
          border-left: 1px solid #FFF;
        }
        &.income-profile {
          border-right: 1px solid #FFF;
        }
        &__range {
          &-value {
            font-size: 42px;
          }
          &-decimal {
            font-size: 30px;
          }
          &-label {
            width: 95px;
          }
        }
      }
      .eta-decimal {
        font-size: 14px;
      }
      .risk-title {
        font-size: 12px;
        background: rgba(255, 255, 255, 0.1);
        &.active {
          background: linear-gradient(173.02deg, rgba(255, 255, 255, 0.2) 18.09%, rgba(255, 255, 255, 0) 50.38%), rgba(255, 255, 255, 0.17);
        }
      }
      .eta-type-details {
        background: rgba(44, 47, 51, 0.3);
        backdrop-filter: blur(42px);
        border-style: solid;
        border-width: 1px 0;
        border-color: #fff;
      }
      .eta-type-description {
        font-size: 13px;
        line-height: 25px;
        letter-spacing: 0.05em;
        color: #000000;
      }
    }
    
    .eta-showcase-content {
      .showCasePayoffChart {
        background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(34.4454px);
        margin-top: 30px;
        padding-bottom: 20px;
        .chartHeader {
          color: grey;
          font-size: 13px;
          padding: 12px 12px 0px 12px;
          .growth-blue-text {
            color: #65CDF3;
          }
          .growth-green-text {
            color: #C7DB6D;
          }
          .growth-red-text {
            color: #DB3155;
          }
          .income-blue-text {
            color: #1C59A8;
          }
          .income-green-text {
            color: #73BD59;
          }
          .income-red-text {
            color: #F5BD1A;
          }
        }
      }
      .cube-col {
        min-width: 340px;
      }
    }
  }
  .eta-action-items {
    .ant-btn-primary {
      background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
      backdrop-filter: blur(42px);
      border: 0.5px solid #FFF;
      color: #FFF;
      font-size: 12px;
      font-weight: 500px;
      z-index: 1;
      &.active{
        background: rgba(255, 255, 255, 0.7);
        color: #151515;
      }
      &:hover, &:focus {
        background: rgba(255, 255, 255, 0.7);
        color: #151515;
      }
    }
 }
  .details {
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    cursor: pointer;
    color: #2C2F33;
    font-size: 22px;
  }
  .eta-slider-content {
    .ant-slider-disabled .ant-slider-track {
      background-color: #9fa1a3 !important;
    }
  }
  .eta-content__info {
    font-weight: 400;
    font-size: 24px;
  }

  .eta-collpased {
    border: 0.5px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 0px;
    &__info {
      margin-bottom: 0px;
    }
    .eta-content {
      border: 0.5px solid #FFFFFF;
      box-sizing: border-box;
      border-radius: 0px;
      .content-spacing {
        padding-left: 5px;
        border-left: 1px solid rgb(138, 132, 132);
      }
    }
  }

  .eta-expanded {
    width: 100%;
    .header-content {
      border: 0.5px solid #FFFFFF;
      box-sizing: border-box;
      border-radius: 0px;
      .allocation-section {
        border-bottom: 1px solid #FFF;
      }
      .label-item {
        font-size: 10px;
        color: #000000;
      }
      .value-item {
        font-size: 10px;
        color: #FFF;
      }
      .learn-more {
        border: 0.5px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 1px;
        padding: 1px;
      }
      .price-section {
        line-height: 14px;
      }
    }
    .eta-content-section {
      color: #000000;
      border: 0.5px solid #FFFFFF;
      box-sizing: border-box;
      border-radius: 0px;
      .section-range-value {
        font-size: 42px;
      }
      .section-range-decimal {
        font-size: 30px;
      }
      .capital-guard-container {
        img {
          width:100px;
          height:120px;
        }
      }
      .capital-guard-content {
        position: absolute;
        top: 25px;
        .capital-guard-value {
          line-height: 30px;
          .capital-value-range {
            font-size: 36px;
          }
          .capital-value-decimal {
            font-size: 19px;
          }
        }
        .capital-guard-label {
          font-size: 10px;
        }
      }
    }
    .footer-content {
      background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
      backdrop-filter: blur(42px);
      border: 1px solid #FFF;
      .section-separator {
        border-right: 1px solid #FFF;
      }
      .slider-buttons {
        cursor: pointer;
        border-bottom: 1px solid #FFF;
      }
      .up-arrow {
        border-right: 1px solid #FFF;
      }
      .share-target-info, .last-offer-info {
        color: #000000;
      }
      .add-portofolio {
        border-top: 1px solid #FFF;
        color: #000000;
      }
    }
  }
}

.eta-index-model {
    position: absolute;
    z-index:1;
}
.eta-index-container {
    position: relative;
    z-index:5;
}