@import "../../../fonts/stylesheet.css";

.subHeader {
  height: 100%;
  position: relative;
  background: linear-gradient(152.62deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 101%), rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(50px);
  font-family: 'DIN 2014 reg';
  .ant-tabs {
    height: 100%;
    background: linear-gradient(152.62deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 101%), rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(50px);
    .ant-tabs-nav {
      padding-left: 50px;
      background: linear-gradient(175.55deg, rgba(255, 255, 255, 0.08) 43.1%, rgba(255, 255, 255, 0) 64.48%), #2C2F33;
      color: white;
      margin: 0 10px 0 10px;
      justify-content: space-between;
      position: absolute;
      z-index: 2;
      width: 96%;
      top: -10px;
      background:transparent url('../../../images/navbar.svg') no-repeat;
      background-size: 100% 100%;
      .ant-tabs-nav-wrap {
        width: 85%;
        flex: inherit !important;
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn, .ant-tabs-tab:hover {
          color: unset;
          font-weight: 900;
        }
        .ant-tabs-tab + .ant-tabs-tab {
          margin: 0 0 0 2rem;
        }
      }
      .ant-tabs-nav-list {
        width: 100%;
        justify-content: space-between;
      }
    }
    .ant-tabs-content-holder {
      height: 100%;
      background: linear-gradient(152.62deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 101%), rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(50px);
      .ant-tabs-content {
        height: 100%;
      }
    }
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none;
  }
  p {
    margin-top: 1em !important;
    margin-bottom: 1em !important;
  }
}
