.page-content {
    min-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: auto;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
}

.white-background {
    background-image: url("../../../images/background-image.png");
}

.dark-background {
    background-image: url("../../../images/dark-background.png");
}