.order-form {
  .section-label {
    font-size: 21px;
    color: #FFF;
  }
  .section-description {
    font-size: 12px;
    color: #FFF;
  }
  
  .section-body {
    color: #FFF;
    .optimised-content {
      background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
      backdrop-filter: blur(40.4002px);
      border-radius: 2.88573px;
      .optimised-content-label {
        font-weight: 300;
        font-size: 21px;
      }
      .chart-container {
        width: 100%;
        height:20px;
        canvas {
          width: 100% !important;
        }
      }
      .step-label {
        font-size: 16px;
        font-weight: bold;
      }
      .growth-price, .income-price, .cash-returned {
        font-size: 13px;
        line-height: 17px;
      }
    }
    .order-info {
      .content {
        color: #2C2F33; 
        background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
        backdrop-filter: blur(40.4002px);
        border-radius: 2.88573px;
        margin-bottom: 20px;
        font-size: 12px;
      }
      .quantity {
        background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
        backdrop-filter: blur(42px);
        border-radius: 3px;
      }
      .statistic-content {
        .ant-statistic-content {
          font-size: 12px;
        }
      }
    }
    .order-form-action {
      .ant-btn-primary {
        border: 0.5px solid #5A6267;
        backdrop-filter: blur(45px);
        color: #5A6267;
        background: none;
        font-size: 11px;
        margin-top: 5px;
      }
    }
  }
}
